//
// Copyright 2023 DXOS.org
//

import { pluginMeta } from '@dxos/app-framework';

export const PRESENTER_PLUGIN = 'dxos.org/plugin/presenter';

export default pluginMeta({
  id: PRESENTER_PLUGIN,
  name: 'Presenter',
  description: 'Present documents as slideshows.',
  icon: 'ph--presentation--regular',
});
