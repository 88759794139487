//
// Copyright 2023 DXOS.org
//

import { pluginMeta } from '@dxos/app-framework';

export const SKETCH_PLUGIN = 'dxos.org/plugin/sketch';

export default pluginMeta({
  id: SKETCH_PLUGIN,
  name: 'Sketch',
  description: 'Digital whiteboard and diagram editor.',
  icon: 'ph--compass-tool--regular',
});
