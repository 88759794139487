//
// Copyright 2023 DXOS.org
//

export const REGISTRY_PLUGIN = 'dxos.org/plugin/registry';

export default {
  id: REGISTRY_PLUGIN,
  name: 'Plugins',
};
