//
// Copyright 2023 DXOS.org
//

export const DECK_PLUGIN = 'dxos.org/plugin/deck';

export default {
  id: DECK_PLUGIN,
  name: 'Deck',
};
