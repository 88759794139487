//
// Copyright 2023 DXOS.org
//

import { pluginMeta } from '@dxos/app-framework';

export const TABLE_PLUGIN = 'dxos.org/plugin/table';

export default pluginMeta({
  id: TABLE_PLUGIN,
  name: 'Tables',
  description: 'Create and manage tables.',
  icon: 'ph--table--regular',
});
